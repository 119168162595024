import * as React from 'react';
import { LandingPage, landingPageLoader } from './Page';

export interface LandingPageLoaderInput { };

export const getRoutes = () => {
    return [{
        path: "/experimental/landing",
        element: <LandingPage />,
        loader: landingPageLoader,
    }];
}