import * as React from 'react';
import { useLoaderData } from 'react-router-dom';
import * as nokapi from '../../../lib/nokapi';
import { DService as Nokapi, Quiz } from '../../../lib/nokapi';

import { LoaderFunction } from 'react-router-dom';
import { QuizPageLoaderInput } from './index';
import { Button, Checkbox, ListGroup, Card } from 'flowbite-react';
import ButtonGroup from 'flowbite-react/lib/esm/components/Button/ButtonGroup';

import {
    Line, XAxis, Tooltip, CartesianGrid, LineChart
} from "recharts";

export interface QuizPageLoaderData {
    participantName: string
    quizList?: Quiz[],
};

export const quizPageLoader = (async ({ request, params }) => {
    return ({
        participantName: params.participantName!,
        quizList: (await Nokapi.dQuizengageQuizList()).results,
    } satisfies QuizPageLoaderData);
}) satisfies LoaderFunction<QuizPageLoaderInput>;

export const QuizPage = () => {
    return <>
        Quiz demo.Missing user.
    </>;
};

interface QuizProps {
    quiz: Quiz,
};

interface ChoiceProps {
    choice: nokapi.Choice;
};

interface QuizChoicesProps {
    choice_set: nokapi.Choice[];
};

const GraphComponent = (props: { x_axis: nokapi.Axis2D, y_axis: nokapi.Axis2D }) => {
    const x_label = props.x_axis.title!;
    const y_label = props.y_axis.title!;
    console.log(x_label, y_label)
    const chart_data = props.x_axis.data_values?.map((_, i) => {
        var ret: any = {};
        ret[x_label] = props.x_axis!.data_values![i];
        ret[y_label] = props.y_axis!.data_values![i];
        return ret;
    });

    console.log(chart_data);
    return <LineChart width={200} height={200} data={chart_data} margin={{ top: 5, right: 20, left: 10, bottom: 20 }}>
        <XAxis dataKey={x_label} label={{ value: x_label, position: 'insideBottom', offset: -5 }} />
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" />
        <Line type="monotone" dataKey={y_label} stroke="#ff7300" yAxisId={0} dot={false} />
    </LineChart >;
}

const ChoiceComponent = (props: ChoiceProps) => {
    return (
        <div style={{ margin: '10px', flex: '1' }}>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ flex: '1' }}>
                    {props.choice.text}
                    {props.choice.x_axis ? <GraphComponent x_axis={props.choice.x_axis!} y_axis={props.choice.y_axis!} /> : <></>}
                </div>
                <div style={{ alignSelf: 'flex-start', marginTop: 'auto' }}>
                    <Button>Choose</Button>
                </div>
            </Card>
        </div>
    );
};

const QuizChoicesComponent = (props: QuizChoicesProps) => {

    return <>
        <ButtonGroup>
            {props.choice_set!.map((choice, i) => <ChoiceComponent key={i} choice={choice} />)}
        </ButtonGroup>
    </>;
};

const QuizComponent = (props: QuizProps) => {
    return <>
        <div>{props.quiz.name}</div>
        <div>{props.quiz.text}</div>
        <QuizChoicesComponent choice_set={props.quiz.choice_set!} />
    </>;
};

export const UserQuizPage = () => {
    const param = useLoaderData() as QuizPageLoaderData;
    return <>
        the quiz for {param.participantName}:
        <br />
        {
            param.quizList?.map((quiz, i) => {
                return <ListGroup key={quiz.id}>
                    <QuizComponent quiz={quiz} />
                </ListGroup>
            })
        }
    </>;
};