import * as React from 'react';

import { Helmet, HelmetProvider } from "react-helmet-async";

export const PrivacyPolicy = () => {
    return <>
        <HelmetProvider>
            <Helmet>
                <title>
                    Privacy | NokLabs
                </title>
            </Helmet>
            <div className="my-5">
                <h1 className="text-3xl font-bold my-4">Privacy Policy</h1>
                <p>
                    This website collects only basic information for functionality and analytics purposes. This may include:
                </p>
                <ul className="list-disc list-inside">
                    <li>Anonymous browsing data (e.g., pages visited)</li>
                    <li>Non-identifiable device information</li>
                </ul>
                <p>
                    This data is used to improve the website and understand user traffic. We do not share any personal information with third parties.
                </p>
                <p>
                    For any inquiries about this policy, please contact info@noklabs.ai.
                </p>
            </div>
        </HelmetProvider>
    </>;
}