import * as React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { pathJoin } from '../../routes/manipulation';
import { DEMO_BASE_PATH } from './routes';

import { Button, ListGroup } from "flowbite-react"


export const DemoPage = () => {
    return <>
        <h1>Demos</h1>
        <div className="flex justify-left">
            <ListGroup>
                <Link to={pathJoin(DEMO_BASE_PATH, "gymlive")}>
                    <ListGroup.Item>
                        GymLive
                    </ListGroup.Item>
                </Link>
                <Link to={pathJoin(DEMO_BASE_PATH, "quiz")}>
                    <ListGroup.Item>
                        Quiz
                    </ListGroup.Item>
                </Link>
            </ListGroup>
        </div >
    </>;
};