import * as React from 'react';
import { useLoaderData } from 'react-router-dom';
import { WebpagesService as NokapiWebpages, LandingPage as LandingPageModel } from '../../../lib/nokapi';

import { LoaderFunction } from 'react-router-dom';
import { LandingPageLoaderInput } from './index';

export interface LandingPageLoaderData {
    landingPage: LandingPageModel | null
};

export const landingPageLoader = (async ({ request, params }) => {
    const response = await NokapiWebpages.webpagesLandingpageList("v1");
    if (response.results && response.results.length > 0) {
        return ({
            landingPage: response.results![0],
        } satisfies LandingPageLoaderData);
    }
    return ({
        landingPage: null,
    } satisfies LandingPageLoaderData);
}) satisfies LoaderFunction<LandingPageLoaderInput>;

// export const LandingPage = () => {
//     return <>
//         V1Landing demo.Missing user.
//     </>;
// };

export const LandingPage = () => {
    const data = useLoaderData() as LandingPageLoaderData;
    React.useEffect(() => {
        NokapiWebpages.webpagesLandingpageList("v1").then((response) => {
            console.log(response);
        });
    }, []);

    const videoRef = React.useRef<HTMLVideoElement>(null);
    React.useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.autoplay = true;
            videoElement.muted = true;
            videoElement.controls = false;
            videoElement.loop = true;
            videoElement.style.width = '100%';
            videoElement.style.height = '100%';
            videoElement.style.objectFit = 'fill';
            videoElement.style.position = 'relative';
            videoElement.style.zIndex = '1';
        }
    }, [videoRef]);

    return (
        <div style={{ position: 'relative', backgroundColor: 'black' }}>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '2',
                }}
            />
            <video ref={videoRef} src={data.landingPage?.background_video.file_blob.file.replace("http://", "https://")} />
        </div>
    );
};
