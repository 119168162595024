import * as React from 'react';

import { Helmet, HelmetProvider } from "react-helmet-async";

import {
    Link
} from "react-router-dom";

const contact = (
    <div className="App">
        <div className="max-w-2xl mx-auto">
            <nav className="border-gray-200">
                <div className="container mx-auto flex flex-wrap items-center justify-between">
                    <Link to="/" className="flex">
                        <svg width="40px" height="40px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <style>{`.a{fill:none;stroke:#800080;stroke-linecap:round;stroke-linejoin:round;}`}</style>
                            </defs>
                            <path className="a" d="M40.5,5.5H7.5a2,2,0,0,0-2,2v33a2,2,0,0,0,2,2h33a2,2,0,0,0,2-2V7.5A2,2,0,0,0,40.5,5.5Z" />
                            <path className="a" d="M29.5605,31.3677V22.1928A5.56,5.56,0,0,0,24,16.6323h0a5.56,5.56,0,0,0-5.5605,5.56v9.1749" />
                            <line className="a" x1="18.4395" y1="22.1928" x2="18.4395" y2="16.6323" />
                        </svg>

                        <span className="self-center text-lg font-semibold whitespace-nowrap">NokLabs</span>
                    </Link>
                    <button data-collapse-toggle="mobile-menu" type="button" className="md:hidden ml-3 text-gray-400 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-lg inline-flex items-center justify-center" aria-controls="mobile-menu-2" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                        <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                    <div className="hidden md:block w-full md:w-auto" id="mobile-menu">
                        <ul className="flex-col md:flex-row flex md:space-x-8 mt-4 md:mt-0 md:text-sm md:font-medium">
                            {/* <li> */}
                            {/* <a href="#" className="bg-blue-700 md:bg-transparent text-white block pl-3 pr-4 py-2 md:text-blue-700 md:p-0 rounded focus:outline-none" aria-current="page">Home</a> */}
                            {/* </li> */}
                            {/* <li> */}
                            {/* <a href="#" className="text-gray-700 hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">Services</a> */}
                            {/* </li> */}
                            {/* <li> */}
                            {/* <a href="#" className="text-gray-700 hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">Pricing</a> */}
                            {/* </li> */}
                            <li>
                                <Link to="/contact" className="text-gray-700 hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <p className="mt-5"> info@noklabs.ai </p>
        </div>
    </div>)

export const ContactPage = () => {
    return <>
        <HelmetProvider>
            <Helmet>
                <title>
                    Contact | NokLabs
                </title>
            </Helmet>
            {contact}
        </HelmetProvider>
    </>;
};