import * as React from 'react';
import { useLoaderData } from 'react-router-dom';
import * as nokapi from '../../../lib/nokapi';

import Webcam from "react-webcam";

import { LoaderFunction } from 'react-router-dom';
import { GymLivePageLoaderInput } from './index';
import { Button, Checkbox, ListGroup, Card } from 'flowbite-react';
import ButtonGroup from 'flowbite-react/lib/esm/components/Button/ButtonGroup';

export const GymLivePage = () => {
    return <>
    </>;
};

// interface GymProps {
//     gym: Gym,
// };
// 
// interface ChoiceProps {
//     choice: nokapi.Choice;
// };
// 
// interface GymChoicesProps {
//     choice_set: nokapi.Choice[];
// };
// 
// const GraphComponent = (props: { x_axis: nokapi.Axis2D, y_axis: nokapi.Axis2D }) => {
//     const x_label = props.x_axis.title!;
//     const y_label = props.y_axis.title!;
//     console.log(x_label, y_label)
//     const chart_data = props.x_axis.data_values?.map((_, i) => {
//         var ret: any = {};
//         ret[x_label] = props.x_axis!.data_values![i];
//         ret[y_label] = props.y_axis!.data_values![i];
//         return ret;
//     });
// 
//     console.log(chart_data);
//     return <LineChart width={200} height={200} data={chart_data} margin={{ top: 5, right: 20, left: 10, bottom: 20 }}>
//         <XAxis dataKey={x_label} label={{ value: x_label, position: 'insideBottom', offset: -5 }} />
//         <Tooltip />
//         <CartesianGrid stroke="#f5f5f5" />
//         <Line type="monotone" dataKey={y_label} stroke="#ff7300" yAxisId={0} dot={false} />
//     </LineChart >;
// }
// 
// const ChoiceComponent = (props: ChoiceProps) => {
//     return (
//         <div style={{ margin: '10px', flex: '1' }}>
//             <Card style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//                 <div style={{ flex: '1' }}>
//                     {props.choice.text}
//                     {props.choice.x_axis ? <GraphComponent x_axis={props.choice.x_axis!} y_axis={props.choice.y_axis!} /> : <></>}
//                 </div>
//                 <div style={{ alignSelf: 'flex-start', marginTop: 'auto' }}>
//                     <Button>Choose</Button>
//                 </div>
//             </Card>
//         </div>
//     );
// };
// 
// const GymChoicesComponent = (props: GymChoicesProps) => {
// 
//     return <>
//         <ButtonGroup>
//             {props.choice_set!.map((choice, i) => <ChoiceComponent key={i} choice={choice} />)}
//         </ButtonGroup>
//     </>;
// };
// 
// const GymComponent = (props: GymProps) => {
//     return <>
//         <div>{props.gym.name}</div>
//         <div>{props.gym.text}</div>
//         <GymChoicesComponent choice_set={props.gym.choice_set!} />
//     </>;
// };
// 
// export const UserGymPage = () => {
//     const param = useLoaderData() as GymPageLoaderData;
//     return <>
//         the gym for {param.participantName}:
//         <br />
//         {
//             param.gymList?.map((gym, i) => {
//                 return <ListGroup key={gym.id}>
//                     <GymComponent gym={gym} />
//                 </ListGroup>
//             })
//         }
//     </>;
// };