import * as React from 'react';

import { getRoutes as privacyGetRoutes} from "./privacy";
import { getRoutes as tosGetRoutes } from "./tos";

import { pathPrefixManipulator } from '../../routes/manipulation';

export const LEGAL_BASE_PATH = "/legal";

export const getRoutes = () => {
    return [
        {
            path: LEGAL_BASE_PATH,
            element: <></>,
        },
        ...privacyGetRoutes().map(pathPrefixManipulator(LEGAL_BASE_PATH)),
        ...tosGetRoutes().map(pathPrefixManipulator(LEGAL_BASE_PATH)),
    ];
}