import * as React from 'react';
import { TermsOfService } from './Page';

export interface GymLivePageLoaderInput { };


export const getRoutes = () => {
    return [
        {
            path: "/tos",
            element: <TermsOfService/>,
        }
    ];
}