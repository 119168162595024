import * as React from 'react';

import { Helmet, HelmetProvider } from "react-helmet-async";

export const TermsOfService = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    ToS | NokLabs
                </title>
            </Helmet>
            <div className="my-5">
                <h1 className="text-3xl font-bold my-4">Terms of Service</h1>
                <ul className="list-disc">
                    <li>
                        <h2 className="text-xl font-semibold">Acceptance</h2>
                        <p>By using this website, you agree to be bound by these Terms of Service.</p>
                    </li>
                    <li>
                        <h2 className="text-xl font-semibold">Acceptable Use</h2>
                        <p>You agree to use this website for lawful purposes only and in accordance with these Terms of Service.</p>
                    </li>
                    <li>
                        <h2 className="text-xl font-semibold">Intellectual Property</h2>
                        <p>All content on this website is protected by intellectual property laws. You may not use any content without written permission.</p>
                    </li>
                    <li>
                        <h2 className="text-xl font-semibold">Disclaimers</h2>
                        <p>This website is provided "as is" without warranty of any kind.</p>
                    </li>
                    <li>
                        <h2 className="text-xl font-semibold">Limitation of Liability</h2>
                        <p>We shall not be liable for any damages arising from your use of this website.</p>
                    </li>
                    <li>
                        <h2 className="text-xl font-semibold">Governing Law</h2>
                        <p>These Terms of Service shall be governed by and construed in accordance with the laws of Ontario, Canada.</p>
                    </li>
                    <li>
                        <h2 className="text-xl font-semibold">Contact Us</h2>
                        <p>For any inquiries about these Terms of Service, please contact info@noklabs.ai.</p>
                    </li>
                </ul>
            </div>
        </HelmetProvider>
    );
}