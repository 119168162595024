import * as React from 'react';

import { BasicLandingPage } from "./Page";
import { getRoutes as vLandingGetRoutes } from "./v";

export const getRoutes = () => {
    return [
        {
            path: "/",
            element: <BasicLandingPage />,
        },
        ...vLandingGetRoutes(),
    ];
}