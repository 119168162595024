import * as React from 'react';
import { UserQuizPage, QuizPage } from './Page';
import { quizPageLoader } from './Page';
import { OpenAPI } from '../../../lib/nokapi';

export interface QuizPageLoaderInput { participantName: string };

OpenAPI.BASE = "https://noklabs.ai";

export const getRoutes = () => {
    return [
        {
            path: "/quiz",
            element: <QuizPage />,
        },
        {
            path: "/quiz/:participantName",
            element: <UserQuizPage />,
            loader: quizPageLoader,
        }
    ];
}