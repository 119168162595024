import * as React from 'react';
import { GymLivePage } from './Page';

export interface GymLivePageLoaderInput { };


export const getRoutes = () => {
    return [
        {
            path: "/gymlive",
            element: <GymLivePage />,
        }
    ];
}