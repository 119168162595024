/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LandingPage } from '../models/LandingPage';
import type { PaginatedLandingPageList } from '../models/PaginatedLandingPageList';
import type { PatchedLandingPage } from '../models/PatchedLandingPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebpagesService {

    /**
     * @param name v1, v2, etc.
     * @param page A page number within the paginated result set.
     * @returns PaginatedLandingPageList
     * @throws ApiError
     */
    public static webpagesLandingpageList(
        name?: string,
        page?: number,
    ): CancelablePromise<PaginatedLandingPageList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/webpages/landingpage/',
            query: {
                'name': name,
                'page': page,
            },
        });
    }

    /**
     * @param requestBody
     * @returns LandingPage
     * @throws ApiError
     */
    public static webpagesLandingpageCreate(
        requestBody: LandingPage,
    ): CancelablePromise<LandingPage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/webpages/landingpage/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this landing page.
     * @returns LandingPage
     * @throws ApiError
     */
    public static webpagesLandingpageRetrieve(
        id: string,
    ): CancelablePromise<LandingPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/webpages/landingpage/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique value identifying this landing page.
     * @param requestBody
     * @returns LandingPage
     * @throws ApiError
     */
    public static webpagesLandingpageUpdate(
        id: string,
        requestBody: LandingPage,
    ): CancelablePromise<LandingPage> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/webpages/landingpage/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this landing page.
     * @param requestBody
     * @returns LandingPage
     * @throws ApiError
     */
    public static webpagesLandingpagePartialUpdate(
        id: string,
        requestBody?: PatchedLandingPage,
    ): CancelablePromise<LandingPage> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/webpages/landingpage/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this landing page.
     * @returns void
     * @throws ApiError
     */
    public static webpagesLandingpageDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/webpages/landingpage/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
