import * as React from 'react';
import { PrivacyPolicy } from './Page';

export interface GymLivePageLoaderInput { };


export const getRoutes = () => {
    return [
        {
            path: "/privacy",
            element: <PrivacyPolicy/>,
        }
    ];
}