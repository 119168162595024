/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Axis2D } from '../models/Axis2D';
import type { Choice } from '../models/Choice';
import type { PaginatedAxis2DList } from '../models/PaginatedAxis2DList';
import type { PaginatedChoiceList } from '../models/PaginatedChoiceList';
import type { PaginatedQuizList } from '../models/PaginatedQuizList';
import type { PatchedAxis2D } from '../models/PatchedAxis2D';
import type { PatchedChoice } from '../models/PatchedChoice';
import type { PatchedQuiz } from '../models/PatchedQuiz';
import type { Quiz } from '../models/Quiz';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DService {

    /**
     * @param page A page number within the paginated result set.
     * @returns PaginatedAxis2DList
     * @throws ApiError
     */
    public static dQuizengageAxis2DList(
        page?: number,
    ): CancelablePromise<PaginatedAxis2DList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/d/quizengage/axis2d/',
            query: {
                'page': page,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Axis2D
     * @throws ApiError
     */
    public static dQuizengageAxis2DCreate(
        requestBody?: Axis2D,
    ): CancelablePromise<Axis2D> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/d/quizengage/axis2d/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this axis2d.
     * @returns Axis2D
     * @throws ApiError
     */
    public static dQuizengageAxis2DRetrieve(
        id: string,
    ): CancelablePromise<Axis2D> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/d/quizengage/axis2d/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique value identifying this axis2d.
     * @param requestBody
     * @returns Axis2D
     * @throws ApiError
     */
    public static dQuizengageAxis2DUpdate(
        id: string,
        requestBody?: Axis2D,
    ): CancelablePromise<Axis2D> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/d/quizengage/axis2d/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this axis2d.
     * @param requestBody
     * @returns Axis2D
     * @throws ApiError
     */
    public static dQuizengageAxis2DPartialUpdate(
        id: string,
        requestBody?: PatchedAxis2D,
    ): CancelablePromise<Axis2D> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/d/quizengage/axis2d/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this axis2d.
     * @returns void
     * @throws ApiError
     */
    public static dQuizengageAxis2DDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/d/quizengage/axis2d/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param page A page number within the paginated result set.
     * @returns PaginatedChoiceList
     * @throws ApiError
     */
    public static dQuizengageChoiceList(
        page?: number,
    ): CancelablePromise<PaginatedChoiceList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/d/quizengage/choice/',
            query: {
                'page': page,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Choice
     * @throws ApiError
     */
    public static dQuizengageChoiceCreate(
        requestBody: Choice,
    ): CancelablePromise<Choice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/d/quizengage/choice/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this choice.
     * @returns Choice
     * @throws ApiError
     */
    public static dQuizengageChoiceRetrieve(
        id: string,
    ): CancelablePromise<Choice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/d/quizengage/choice/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique value identifying this choice.
     * @param requestBody
     * @returns Choice
     * @throws ApiError
     */
    public static dQuizengageChoiceUpdate(
        id: string,
        requestBody: Choice,
    ): CancelablePromise<Choice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/d/quizengage/choice/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this choice.
     * @param requestBody
     * @returns Choice
     * @throws ApiError
     */
    public static dQuizengageChoicePartialUpdate(
        id: string,
        requestBody?: PatchedChoice,
    ): CancelablePromise<Choice> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/d/quizengage/choice/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this choice.
     * @returns void
     * @throws ApiError
     */
    public static dQuizengageChoiceDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/d/quizengage/choice/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param page A page number within the paginated result set.
     * @returns PaginatedQuizList
     * @throws ApiError
     */
    public static dQuizengageQuizList(
        page?: number,
    ): CancelablePromise<PaginatedQuizList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/d/quizengage/quiz/',
            query: {
                'page': page,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Quiz
     * @throws ApiError
     */
    public static dQuizengageQuizCreate(
        requestBody: Quiz,
    ): CancelablePromise<Quiz> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/d/quizengage/quiz/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this quiz.
     * @returns Quiz
     * @throws ApiError
     */
    public static dQuizengageQuizRetrieve(
        id: string,
    ): CancelablePromise<Quiz> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/d/quizengage/quiz/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique value identifying this quiz.
     * @param requestBody
     * @returns Quiz
     * @throws ApiError
     */
    public static dQuizengageQuizUpdate(
        id: string,
        requestBody: Quiz,
    ): CancelablePromise<Quiz> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/d/quizengage/quiz/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this quiz.
     * @param requestBody
     * @returns Quiz
     * @throws ApiError
     */
    public static dQuizengageQuizPartialUpdate(
        id: string,
        requestBody?: PatchedQuiz,
    ): CancelablePromise<Quiz> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/d/quizengage/quiz/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique value identifying this quiz.
     * @returns void
     * @throws ApiError
     */
    public static dQuizengageQuizDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/d/quizengage/quiz/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
