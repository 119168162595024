import * as React from 'react';

import { DemoPage } from "./Page";

import { getRoutes as quizGetRoutes } from "./quiz";
import { getRoutes as gymLiveGetRoutes } from "./gymlive";
import { pathPrefixManipulator } from '../../routes/manipulation';
import { DEMO_BASE_PATH } from './routes';

export const getRoutes = () => {
    return [
        {
            path: DEMO_BASE_PATH,
            element: <DemoPage />,
        },
        ...quizGetRoutes().map(pathPrefixManipulator(DEMO_BASE_PATH)),
        ...gymLiveGetRoutes().map(pathPrefixManipulator(DEMO_BASE_PATH)),
    ];
}