import { getRoutes as contactGetRoutes } from "../features/contact";
import { getRoutes as landingGetRoutes } from "../features/landing";
import { getRoutes as demoGetRoutes } from "../features/d";
import { getRoutes as legalGetRoutes } from "../features/legal";

contactGetRoutes();

export const getAppRoutes = () => {
    return [
        {
            path: "/",
            children: [
                ...landingGetRoutes(),
                ...contactGetRoutes(),
                ...demoGetRoutes(),
                ...legalGetRoutes(),
            ]
        }
    ];
}